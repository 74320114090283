import { Injectable } from '@angular/core';
import {
  FlowVaNavigatorMenuItemModel,
  ProjectFlowSummaryDto,
} from 'src/app/projects/proxy/project.models';
import { PROJECT_FLOW_VIRTUAL_AGENT_NAVIGATOR_KEY } from '../shared.consts';

@Injectable({ providedIn: 'root' })
export class FlowVaNavigatorService {
  private _currentProjectVersionId: string;
  get currentProjectVersionId(): string {
    return this._currentProjectVersionId;
  }
  set currentProjectVersionId(value: string) {
    this._currentProjectVersionId = value;
    this.updateSessionStorage();
  }

  private _selectedProjectFlow: ProjectFlowSummaryDto;
  get selectedProjectFlow(): ProjectFlowSummaryDto {
    return this._selectedProjectFlow;
  }
  set selectedProjectFlow(value: ProjectFlowSummaryDto) {
    this._selectedProjectFlow = value;
    this.updateSessionStorage();
  }

  private _selectedVirtualAgent: FlowVaNavigatorMenuItemModel;
  get selectedVirtualAgent(): FlowVaNavigatorMenuItemModel {
    return this._selectedVirtualAgent;
  }
  set selectedVirtualAgent(value: FlowVaNavigatorMenuItemModel) {
    this._selectedVirtualAgent = value;
    this.updateSessionStorage();
  }

  constructor() {
    const selectedFlowVAStr = localStorage.getItem(PROJECT_FLOW_VIRTUAL_AGENT_NAVIGATOR_KEY);

    if (selectedFlowVAStr) {
      const selectedFlowVA: {
        currentProjectVersionId: string;
        selectedProjectFlow: ProjectFlowSummaryDto;
        selectedVirtualAgent: FlowVaNavigatorMenuItemModel;
      } = JSON.parse(selectedFlowVAStr);

      this.currentProjectVersionId = selectedFlowVA.currentProjectVersionId;
      this.selectedProjectFlow = selectedFlowVA.selectedProjectFlow;
      this.selectedVirtualAgent = selectedFlowVA.selectedVirtualAgent;
    }
  }

  private updateSessionStorage() {
    const selectedFlowVA = {
      currentProjectVersionId: this.currentProjectVersionId,
      selectedProjectFlow: this.selectedProjectFlow,
      selectedVirtualAgent: this.selectedVirtualAgent,
    };

    localStorage.setItem(PROJECT_FLOW_VIRTUAL_AGENT_NAVIGATOR_KEY, JSON.stringify(selectedFlowVA));
  }
}
