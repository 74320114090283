import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ChannelInput } from 'src/app/integrations/proxy/integration.model';
import { TOASTER_LIFE } from 'src/app/shared/shared.consts';
import { BotHubSettingsService } from './proxy/bothub-settings.service';

@Component({
  selector: 'cai-bothub-settings',
  templateUrl: './bothub-settings.component.html',
})
export class BotHubSettingsComponent implements OnInit {
  constructor(
    private service: BotHubSettingsService,
    private fb: UntypedFormBuilder,
    private toaster: ToasterService
  ) {}
  inputs: ChannelInput[] = [];
  form: UntypedFormGroup;
  isLoading = false;

  ngOnInit() {
    this.setSettings();
  }

  buildForm() {
    this.form = this.fb.group({});
    this.inputs.forEach((channelInput: ChannelInput) => {
      this.form.addControl(
        channelInput.name,
        new UntypedFormControl(
          channelInput.type === 'bool'
            ? this.convertBoolean(channelInput.defaultValue)
            : channelInput.defaultValue,
          channelInput.isMandatory ? Validators.required : undefined
        )
      );
    });
  }

  setSettings() {
    this.service.get().subscribe((response: ChannelInput[]) => {
      this.inputs = response;
      this.buildForm();
    });
  }

  submitForm() {
    this.isLoading = true;
    const formToSend: any = {};
    this.inputs.forEach((input: ChannelInput) => {
      if (input.type === 'number') {
        formToSend[input.name] = Number(this.form.value[input.name]);
      } else {
        formToSend[input.name] = this.form.value[input.name];
      }
    });
    this.service.update(formToSend).subscribe(
      () => {
        this.isLoading = false;
        this.toaster.success('LeptonThemeManagement::SuccessfullySaved', null, {
          life: TOASTER_LIFE,
        });
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  onChangeNumberInput(value: string, item: ChannelInput) {
    if (!item.range || item.range.length !== 2) {
      return;
    }
    if (Number(value) < Number(item.range[0])) {
      this.form.patchValue({
        [item.name]: item.range[0],
      });
    } else if (Number(value) > Number(item.range[1])) {
      this.form.patchValue({
        [item.name]: item.range[1],
      });
    } else {
      this.form.patchValue({
        [item.name]: Number(value),
      });
    }
  }

  convertBoolean(value: string) {
    if (value === 'True' || value === 'true') {
      return true;
    } else {
      return false;
    }
  }
}
