import { Component } from '@angular/core';
import { NotificationDto, NotificationModel, NotificationType } from '@sestek/notification';
import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { FileDownloadHelperService } from 'src/app/shared/services/file-download-helper.service';
import { TOASTER_LIFE } from 'src/app/shared/shared.consts';
import { DateFormatterPipe } from 'src/app/shared/pipes/date-formatter.pipe';
import { DateDisplayType } from 'src/app/shared/enums/date-display-type';
import { TopIntentsExcelExportedNotificationModel } from '../models/top-intents-excel-exported-notification-model';
import { DashboardService } from 'src/app/dashboard/proxy/dashboard.service';

@Component({
  selector: 'cai-top-intents-exported-notification',
  templateUrl: './top-intents-excel-exported-notification.component.html',
  styleUrls: ['./top-intents-excel-exported-notification.component.css'],
})
@NotificationType('TopIntentListExcelExportedNotification')
export class DashboardTopIntentsExportedNotificationComponent {
  _data: NotificationDto;

  get data() {
    return this._data;
  }
  set data(data: NotificationDto) {
    this._data = data;
    this.notification = JSON.parse(data.payload);
  }

  notification: TopIntentsExcelExportedNotificationModel;

  constructor(
    private localizationService: LocalizationService,
    private toasterService: ToasterService,
    private fileDownloadHelperService: FileDownloadHelperService,
    private dateFormatterPipe: DateFormatterPipe,
    private dashboardService: DashboardService
  ) {}

  showNotification(notification: NotificationModel) {
    this.notification = JSON.parse(notification.payload);

    const message = this.localizationService.instant('Dashboard::TopIntentsFileReady');

    this.toasterService.success(message, '', { life: TOASTER_LIFE });
  }

  downloadFile() {
    let nowString = this.dateFormatterPipe.transform(
      new Date(),
      DateDisplayType.shortDateShortTime
    );

    nowString = nowString.replace(/[^a-zA-Z0-9]/g, '_');

    const fileName = `${this.localizationService.instant(
      'Dashboard::TopIntentsReport:ListReportFileNamePrefix'
    )}_${nowString}`;

    this.dashboardService.exportTopIntentsExcel(this.notification.fileId).subscribe(
      (result) => {
        this.fileDownloadHelperService.handleDownloadFileResult(result, fileName);
      },
      (error) => {
        this.fileDownloadHelperService.handleDownloadFileError(error);
      }
    );
  }
}
