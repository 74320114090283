<div class="mention-list-modal mt-2 shadow rounded p-2" [style.top.px]="30">
  <div class="d-flex">
    <input
      class="form-control form-control-sm"
      type="text"
      placeholder="{{ '::Search' | abpLocalization }}"
      [(ngModel)]="searchTerm"
      (input)="setFilteredMentions()"
    />
    <span ngbTooltip="{{ '::AddDynamicContext' | abpLocalization }}">
      <a class="me-2 add-mention d-block" (click)="onAddMention()">
        <i class="fa fa-plus-circle"></i>
      </a>
    </span>
  </div>
  <div class="mention-list">
    <div class="mention-list-wrapper">
      <ul class="list-unstyled mt-2 mb-0">
        <!-- | filter: searchTerm -->
        <li class="mb-1" *ngFor="let mention of filteredMentions" title="{{ mention.value }}">
          <span
            (click)="onMentionSelected(mention)"
            class="c-pointer rounded d-inline-block mention-name
            {{ mention.type === 'static' && 'mention-static' }}
            {{ mention.type === 'intentEntity' && 'mention-intentEntity' }}
            {{ mention.type === 'dynamic' && 'mention-dynamic' }}
            {{ mention.type === 'projectEntity' && 'mention-projectEntity' }}
            {{
              mention.type !== 'dynamic' &&
                mention.type !== 'static' &&
                mention.type !== 'intentEntity' &&
                mention.type !== 'projectEntity' &&
                'mention-undefined'
            }}"
          >
            {{ mention.key }}
          </span>

          <div *ngIf="mention.type === 'dynamic'" class="float-end show-button-group d-none">
            <span (click)="onEditMention(mention)" class="c-pointer fa fa-edit me-2"></span>
            <span (click)="onRemoveMention(mention)" class="c-pointer fa fa-trash"></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
