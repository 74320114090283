import { LocalizationService } from '@abp/ng.core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  transform(value: number): string {
    const averageSessionDuration: { hour?: number; minute?: number; second?: number } = {
      hour: Math.floor(value / 3600),
      minute: Math.floor((value % 3600) / 60),
      second: Math.floor((value % 3600) % 60),
    };

    const durationExpressions = [];

    if (averageSessionDuration.hour) {
      durationExpressions.push(averageSessionDuration.hour);
      durationExpressions.push(this.localizationService.instant('::HourAbbreviation'));
    }

    if (averageSessionDuration.minute) {
      durationExpressions.push(averageSessionDuration.minute);
      durationExpressions.push(this.localizationService.instant('Dashboard::MinuteAbbreviation'));
    }

    if (averageSessionDuration.second) {
      durationExpressions.push(averageSessionDuration.second);
      durationExpressions.push(this.localizationService.instant('Dashboard::SecondAbbreviation'));
    }

    return durationExpressions.join(' ');
  }
}
